import React from 'react'

function Timeline() {
  return (
    <div id="timeline">Timeline
        <div id="tl-line"></div>

    </div>
  )
}

export default Timeline